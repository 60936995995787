.table :global(.ant-table-tbody > tr > td){
    padding: 0!important;
}
.table thead th{
    font-size: 16px!important;
    color: #707070;
    font-weight: bold;
}

.cell{
    color: #0e9eff;
}
.cell:hover{
    background-color: #e5e5e5;
    cursor: pointer;
}
.row_sum td{
    background-color: #e3f5ff !important;
}
.celldanger{
    background-color: #ffdede;
}