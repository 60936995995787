.secondSider {
    transition: all .2s;
    width: 126px;
    height: 100%;
    margin-left: 80px;
    padding-bottom: 40px;
    background-color: #fff;
    border-right: 1px solid #ebedf0;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
}
.secondSider :global(.ant-menu-inline){
    border-right: none;
}

.menuItem{
    /*padding: 0 16px !important;*/

    width: 126px!important;
    height: 56px!important;
    margin: 0!important;
    padding-left: 20px!important;
}
.menuItem :global(.anticon){
    /*margin-right: 5px !important;*/
}

.title{
    padding:15px 20px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebedf0;
    color: #323233;

    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
}

.menuItem :global(.ant-menu-submenu-title){
    padding: 0 !important;
}

.menuItem:global(.ant-menu-item::after){
    border: none!important;
}

.menuItem:global(.ant-menu-item-selected){
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
}