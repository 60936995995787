.events {
    list-style: none;
    margin: 0;
    padding: 0;
}
.events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}
.notes-month {
    text-align: center;
    font-size: 28px;
}
.notes-month section {
    font-size: 28px;
}


.calendar :global(.ant-fullcalendar td){
    vertical-align: top;
    height: auto;
}
.calendar :global(.ant-fullcalendar-date){
    height: auto;
    min-height: 116px;
}
.calendar :global(.ant-fullcalendar-content){
    height: auto;
    min-height: 88px;
}
.calendar :global(.ant-fullcalendar-content::-webkit-scrollbar){
    display:none
}

.calendar :global(.ant-badge){
    white-space: nowrap;
}

.timeItem{
    width: 60px;
    height: 30px;
    background-color: #f9f9f9;
    border: solid 1px #ffffff;
    text-align: center;
    line-height: 30px;
    box-sizing: border-box;
}

.timeItemActive{
    composes:timeItem;
    background-color: rgba(33, 241, 32, 0.23);
}
.timeItemHead{
    background-color: #eeeeee;
}
.timeItemGroup{
    position: relative;
}
.toolbar{
    margin-bottom: 20px;
}
.yuyue{
    position: absolute;
    box-sizing: border-box;
    /*padding: 3px 5px;*/
    /*border:solid 1px #ffffff;*/
    border-radius: 5px;
    background-color: #91d5ff;
    overflow: auto;
    top:0;
    left: 0;
    right: 0;
}

.yuyue0{
    composes: yuyue;
    background-color: #b3e2ff;
}
.yuyue1{
    composes: yuyue;
    background-color: #fff655;
}
.yuyue2{
    composes: yuyue;
    background-color: #71ff6c;
}

.yuyue-1{
    composes: yuyue;
    background-color: rgba(121, 121, 255, 0.6);
}