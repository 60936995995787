.wrapper{
    padding: 0;
}
.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
}


.title{
    font-size: 18px;
}

.actions{
    font-size: 16px;
}

:global(.fullscreen-enabled) {
    background-color: #ffffff;
}