body,
html {
    /*margin: 0;*/
    /*font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir;*/
    /*background: #f0f0f0;*/
    /*height: 100vh;*/
}

* {
    /*box-sizing: border-box;*/
    /*user-select: none;*/
    /*-moz-user-select: none;*/
}

.container {
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    height: calc(100vh - 122px);
    /*width: calc(100vw - 262px);*/
    flex: 1;
    margin-left: 16px;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    box-sizing: border-box;
    user-select: none;
    -moz-user-select: none;
}

/*.container > div{*/
/*position: relative;*/
/*!*min-width: 2000px;*!*/
/*!*min-height: 3000vh;*!*/
/*background: grey;*/
/*border-radius: 5px;*/
/*!*box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);*!*/
/*!*transition: box-shadow 0.5s, opacity 0.5s;*!*/
/*will-change: transform;*/
/*!*border: 10px solid white;*!*/
/*cursor: grab;*/
/*overflow: hidden;*/
/*touch-action: none;*/
/*display: flex;*/
/*}*/

/*.container > div > div {*/
/*will-change: transform;*/
/*height: 100%;*/
/*margin: 0vw 0;*/
/*}*/

/*.container > div > div > * {*/
/*height: 100%;*/
/*background-size: cover;*/
/*background-position: center center;*/
/*margin: 0vw 0;*/
/*}*/

.container > div.dragging {
    cursor: grabbing;
}

.container > div.disabled {
    opacity: 0.5;
    cursor: default;
}

.container > div:hover {
    /*box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);*/
}

.column {
    width: 80px;
    background-color:  #E3E4E5;
    /*border-left: solid 1px rgba(0, 0, 0, 0.06);*/
}

.timeline {
    position: absolute;
    background-image: linear-gradient(rgba(176, 176, 176, 0.8) 33%,rgba(176, 176, 176, 0.5) 33%,rgba(176, 176, 176, 0.5) 66%,rgba(176, 176, 176, 0.3) 66%);
    width: 10px;
    height: 1px;
    z-index: 100;
    font-size: 16px;
    border-top: solid 1px red;
    color: red;
}
.timeline span{
    position: absolute;
    top: -20px;
}
.hour-cell {
    position: relative;
    z-index: 10;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.06);

}

.column-title-container {
    /*width: 100%;*/
    overflow: hidden;
    height: 30px;
}

.column-title-wrapper {
    display: flex;
    flex-wrap: nowrap;
}

.column-title {
    text-align: center;
    background-color: #DBE0E7;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    border-left:solid 1px #cccccc;
    font-weight: bold;
}

.time-column-container {
    width: 80px;
    text-align: center;
    background-color: #ffffff;
    position: relative;
    /*overflow: hidden;*/
}

.time-column-title {
    height: 30px;
    line-height: 30px;
    background-color: #DBE0E7;
    position: relative;
    z-index: 999;
    font-weight: bold;
}

.time-column-cell {
    position: relative;
}

.time-column-cell span {
    /*position: absolute;*/
    /*top: -5px;*/
    position: absolute;
    top: 1px;
    right: 10px;
}

.column-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.table-container {
    flex: 1;
    overflow: scroll;
    display: flex;
    /*width: calc(100vw - 240px);*/
}

.table-wrapper {
    position: relative;
    /*min-width: 2000px;*/
    /*min-height: 3000vh;*/
    /*background: grey;*/
    /*border-radius: 5px;*/
    /*box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);*/
    /*transition: box-shadow 0.5s, opacity 0.5s;*/
    will-change: transform;
    /*border: 10px solid white;*/
    /*cursor: grab;*/
    /*overflow: hidden;*/
    touch-action: none;
    display: flex;
}
.time-column-cells{
    position: relative;
}
.time-column-current-time{
    position: absolute;
    border-top: solid 1px red;
    width: calc(100vw - 160px);
    top: 100px;
    z-index: 99;
}
.time-column-current-time:before{
    display: block;
    width: 10px;
    height: 10px;
    background-color: red;
    top: -5px;
    left: 0;
    border-radius: 5px;
    position: absolute;
    content: " ";
}
.event-cell{
    position: absolute;
    /*background-color: #ffc4be;*/
    will-change: top, left;
    /*border-left-width: 3px;*/
    /*border-left-style: solid;*/
    /*!*border-left:solid 3px red;*!*/
    /*padding-left: 3px;*/
    z-index: 101;
    overflow: auto;
    /*border-bottom: solid 20px #f5f5f5;*/
    display: flex;
    flex-direction: column;
    /*border-left-width: 3px;*/
    /*border-left-style: solid;*/
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
}

.event-cell .event-cell-title{
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    height: 20px;
    overflow: hidden;
    background: #DBE0E7;
    padding: 0 6px;
    position: relative;
}
/*.event-cell:hover{*/
/*transform: scale(1.1);*/
/*}*/
.event-cell .event-cell-content{

    /*border-left:solid 3px red;*/
    /*padding-left: 3px;*/
    overflow: auto;
    flex:1;
    background: #EDF3FA;
}
/*.event-cell:after{*/
/*display: block;*/
/*height: 20px;*/
/*content: " ";*/
/*background-color: #f5f5f5;*/
/*}*/
.event-cell .event-cell-extra{
    background: rgba(0, 0, 0, 0.06);
    position: absolute;
    bottom: 0;
    right:0;
    width: 100%;
}
.event-cell .event-cell:hover{
    /*border:solid 2px #0e9eff;*/
}
.event-cell .event-cell-lock{
    position: absolute;
    right: 6px;
    top:4px;
}
.event-cell-2{
    position: absolute;
    /*background-color: #ffc4be;*/
    will-change: top, left;
    /*border-left-width: 3px;*/
    /*border-left-style: solid;*/
    /*!*border-left:solid 3px red;*!*/
    /*padding-left: 3px;*/
    z-index: 1;
    overflow: auto;
    /*border-bottom: solid 20px #f5f5f5;*/
    display: flex;
    flex-direction: column;
    /*border-left-width: 3px;*/
    /*border-left-style: solid;*/
    /*border-radius: 5px;*/
}