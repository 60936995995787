.header {
    background-image: url("./images/header-bg2.svg");
    background-repeat: no-repeat;
    margin: 16px 40px 40px 40px;
    width: 1840px;
    height: 104px;
}

.header .logo {
    background-image: url("./images/logo.svg");
    background-repeat: no-repeat;
    position: absolute;
    left: 32px;
    top: 32px;
    width: 236.73px;
    height: 27px;
}

.header .rightBar {
    position: absolute;
    top: 28px;
    right: 28px;
    display: flex;
    align-items: end;
}

.header .weather {
    /*position: absolute;*/

}

.bodyContainer {
    /*padding: 40px;*/
    /*display: flex;*/
}

.leftContainer {

}

.mapContainer {
    position: absolute;
    top: 400px;
    left: 90px;
}

.mapContainer .map {
    position: relative;
    width: 1239px;
    height: 206px;
    background-image: url("./images/地图.png");
    background-repeat: no-repeat;
    z-index: 100;
}
.mapContainer .mapbg{
    background-image: url("./images/地图阴影.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 50px;
    width: 1238px;
    height: 245px;
    z-index: 9;
    content: "";
    animation: naturalFloat 4s infinite;
}
@keyframes naturalFloat {
    0%, 100% {
        top: 50px;
        animation-timing-function: ease-out; /* 开始和结束时缓慢 */
    }
    50% {
        top: 20px; /* 浮动的最高点 */
        animation-timing-function: ease-in; /* 到达最高点时开始减速 */
    }
}
.mapContainer .map .item{
    position: absolute;
    background-image: url("./images/在马房支柱.png");
    width: 34px;
    height: 70px;
    background-repeat: no-repeat;
    top: 35px;
    left: 340px;
}
.mapContainer .map .item:before{
    position: absolute;
    background-image: url("./images/在马房icon.png");
    background-repeat: no-repeat;
    width: 108px;
    height: 108px;
    content: "";
    top: -70px;
    left: -36px;
}
.mapContainer .map .item2{
    position: absolute;
    background-image: url("./images/不在马房支柱.png");
    width: 34px;
    height: 70px;
    background-repeat: no-repeat;
    top: 35px;
    left: 340px;
}

.mapContainer .map .item2:before{
    position: absolute;
    background-image: url("./images/外出马匹icon.png");
    background-repeat: no-repeat;
    width: 108px;
    height: 108px;
    content: "";
    top: -70px;
    left: -36px;
}
.mapContainer .map .zdgz{
    position: absolute;
    background-image: url("./images/重点关注icon2.svg");
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    top: -40px;
    left: 20px;
}
.mapContainer .iteminfo{
    position: absolute;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    color: #FFFFFF;

    display: flex;
    width: 800px;
    justify-content: space-between;
    z-index: 10;
}
.mapContainer .iteminfo>div{
    position: relative;
    display: block;
    line-height: 108px;
    padding-left: 90px;
}
.mapContainer .iteminfo>div:before{
    position: absolute;
    background-repeat: no-repeat;
    width: 108px;
    height: 108px;
    content: "";
    left: 0;
    top: 0;
}
.mapContainer .iteminfo1:before{
    background-image: url("./images/在马房icon.png");
}
.mapContainer .iteminfo2:before{
    background-image: url("./images/外出马匹icon.png");
}
.mapContainer .iteminfo3:before{
    background-image: url("./images/需打扫马房icon.png");
}
.mapContainer .iteminfo4:before{
    background-image: url("./images/重点关注icon.png");
}

.hjzt {
    position: absolute;
    top: 177px;
    left: 109px;
    width: 1200px;
    height: 85px;
    display: flex;
    justify-content: space-between;
    color: #91CAFF;
}

.hjzt .item {
    display: flex;
}

.hjzt .item .item_icon {
    margin-right: 31.85px;
}

.hjzt .item .item_value {
    font-size: 42.38px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
}

.hjzt .item .item_name {
    font-size: 23.84px;
    font-weight: 250;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
}

.jrkb {
    position: absolute;
    top: 156px;
    left: 40px;
    width: 1099px;
    height: 894px;
    background-image: url("./images/jrkb-bg.svg");
    background-repeat: no-repeat;
    padding: 98px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}

.wyjl {
    position: absolute;
    top: 773px;
    left: 32px;
    width: 1370px;
    height: 275px;
    background-image: url("./images/wyjl_bg.svg");
    background-repeat: no-repeat;
    padding: 82px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}

.tsgz {
    position: absolute;
    top: 144px;
    left: 1434px;
    width: 454px;
    height: 472px;
    background-image: url("./images/tsgz_bg.svg");
    background-repeat: no-repeat;
    padding: 98px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}
.tsgzNum{
    position: absolute;
    top: 0;
    left: 10px;

    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;

    color: #FFFFFF;
}

.wxrw {
    position: absolute;
    top: 648px;
    left: 1434px;
    width: 454px;
    height: 400px;
    background-image: url("./images/wxrw_bg.svg");
    background-repeat: no-repeat;
    padding: 98px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}