@import '~handsontable/dist/handsontable.full.css';

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    /*height: 32px;*/
    /*background: rgba(255,255,255,.2);*/
    margin: 16px;
    /*color:#ffffff;*/
    font-size: 30px;
    text-align: center;
}

.logo img {
    max-width: 100%;
    max-height: 57px;
}

.antd-pro-components-sider-menu-index-sider {
    position: relative;
    z-index: 10;
    min-height: 100vh;
    box-shadow: 2px 0 6px rgba(0, 21, 41, .1);
    /*background-color: #35579e !important;*/
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    /*background-color: #35579e !important;*/

}

.ant-advanced-search-form {
    /*padding: 24px;*/
    /*background: #fbfbfb;*/
    /*border: 1px solid #d9d9d9;*/
    /*border-radius: 6px;*/
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    /*margin: 0 -5px;*/
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
    margin: 0 5px;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

.ant-page-header {
    /*padding: 8px 24px !important;*/
    padding: 0 !important;
}

.text-red{
    color: #ff0000;
}
.text-green{
    color: #00a850;
}
.text-gray{
    color: #aaaaaa;
}