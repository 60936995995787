.cell {
    display: block;
}

.cell:hover {
    background: #f5f5f5;
}
.cellselected{
    background: #1890ff;
    color: #ffffff;
}
:global(.ant-picker-cell-disabled::before){
    background: none;
}
:global(.ant-picker-cell-today::before){
    border: solid 1px  #1890ff;
}