.dateWapper{
    display: flex;
    overflow: auto;
    justify-content: space-between;
    user-select: none;
}

.dateWeek{
    padding: 2px;
    text-align: center;
    cursor: pointer;
}
.date{
    /*background-color: #0e9eff;*/
    color: rgba(0, 0, 0, 0.65);
    border-radius: 20px;
    /*padding: 5px;*/
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.dateWeek:global(.active) .date{
    background-color: #0e9eff;
    color: #ffffff;
}
.dateWeek:global(.weekend) {
    color: #ff0024;
}



.timeItem{
    width: 80px;
    /*height: 30px;*/
    background-color: #f9f9f9;
    border: solid 1px #ffffff;
    text-align: center;
    line-height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
}

.timeItemActive{
    composes:timeItem;
    background-color: rgba(33, 241, 32, 0.23);
}
.timeItem:hover{
    /*box-shadow: 3px 3px 3px rgba(0,0,0,0.6);*/
    /*z-index: 100;*/
    border: solid 1px #00a850;
}
.timeItemHead{
    background-color: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.timeItemGroup{
    position: relative;
}
.toolbar{
    margin-bottom: 20px;
}
.yuyue{
    position: absolute;
    box-sizing: border-box;
    padding: 3px 5px;
    border:solid 1px #ffffff;
    border-radius: 5px;
    background-color: #91d5ff;
    overflow: auto;
    top:0;
    left: 0;
    right: 0;
}

.yuyue0{
    composes: yuyue;
    background-color: rgba(248, 255, 7, 0.6);
}
.yuyue1{
    composes: yuyue;
    background-color: rgba(33, 241, 32, 0.6);
}

.yuyue-1{
    composes: yuyue;
    background-color: rgba(33, 241, 32, 0.6);
}

.datePicker{
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.timeItemHead i {
    font-size: 18px;
}

.timeItemHead i:hover {
    color: #0045ff;
}