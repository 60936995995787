.header {
    background-image: url("./images/header-bg.svg");
    background-repeat: no-repeat;
    margin: 16px 40px 40px 40px;
    width: 1840px;
    height: 104px;
}
.header .logo{
    background-image: url("./images/logo.svg");
    background-repeat: no-repeat;
    position: absolute;
    left: 32px;
    top: 32px;
    width: 236.73px;
    height: 27px;
}

.header .rightBar{
    position: absolute;
    top:28px;
    right: 28px;
    display: flex;
    align-items: end;
}

.header .weather{
    /*position: absolute;*/

}
.bodyContainer{
    /*padding: 40px;*/
    /*display: flex;*/
}
.leftContainer{

}
.jrkb{
    position: absolute;
    top: 156px;
    left: 40px;
    width: 1099px;
    height: 894px;
    background-image: url("./images/jrkb-bg.svg");
    background-repeat: no-repeat;
    padding: 98px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}

.mfgqws{
    position: absolute;
    top: 156px;
    left: 1166px;
    width: 724px;
    height: 294px;
    background-image: url("./images/mfgqws-bg.svg");
    background-repeat: no-repeat;
    padding: 98px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}

.zrmfws{
    position: absolute;
    top: 482px;
    left: 1166px;
    width: 724px;
    height: 568px;
    background-image: url("./images/zrmfws-bg.svg");
    background-repeat: no-repeat;
    padding: 98px 2px 2px 2px;
    overflow: hidden;
    border-radius: 0px 18px 18px 18px;
}