.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
}

.menuContainer {
    /*position: absolute;*/
    /*top: 50px;*/
    /*right: 50px;*/
    height: 72px;
    display: flex;
    text-align: center;
    /*box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.1);*/
    border-left: solid 1px #dddddd;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 16px;
}
/*.menuContainer :global(.ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span){*/
    /*margin-left: 0!important;*/
/*}*/

.menu :global(.ant-menu-item .anticon + span){
    margin-left: 0!important;
}
.eventcalendar {
    flex: 1;
    width: calc(100vw - 80px);

    /*width: calc(100vw - 160px);*/
    display: flex;
    overflow: hidden;
}

.dateMenu {
    /*height: 60px;*/
    width: 150px;
    margin-left: 16px;
    margin-right: 16px;
    overflow: auto;
}

.dateMenu :global(.ant-menu-item) {
    line-height: 29px;
}


.dateMenu .dateItem{
    height: 80px;
    background: #FFFFFF;
    padding: 16px;
    border-bottom: solid 1px #EEEEEE;

    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0em;

    font-feature-settings: "kern" on;
    /* Text/colorTextHeading */
    color: rgba(0, 0, 0, 0.88);

    cursor: pointer;
}

.dateMenu .dateItem:hover{
    background: #E6F8FF;
    color: #0974D9;
}
.dateMenu .current {
    background: #E6F8FF;
    font-weight: bold;
    /* D7 */
    color: #0974D9;
    border-left: solid 4px #0974D9;
}

.dateMenu .today {
    color: #ff0300;
}

:global(.ant-menu-sub .ant-menu-item) div {
    float: left;
}

.menu {
    /*border-right: 0;*/
}

.menu :global(.ant-menu-item) {
    font-size: 18px;
}

.menu :global(.ant-menu-item .anticon) {

    font-size: 30px;
    line-height: inherit;
    margin-bottom: 5px;
    margin-right: 0;
}
.menuContainer :global(.ant-checkbox + span){
    padding-left: 0 !important;
}

.menu :global(.ant-menu-item .anticon + span) {
    /*font-size: 30px;*/
    display: block;
    opacity: inherit;
    text-align: center;
    max-width: initial;
    font-size: 16px;
}

.menu :global(.ant-menu-item) {
    height: auto;
    padding: 10px 0 !important;
    line-height: 20px;
    text-align: center;
}

.eventContent {
    /*display: flex;*/
    padding: 0 4px;
}

.eventContent span {
    /*flex: 1;*/
    word-break: break-all;
}

.eventContentTitle{
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 8px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
}

.state {
    /*position: absolute;*/
    /*right: 3px;*/
    /*top: 5px;*/
    font-size: 9px;
    /*border: solid 3px #00eb24;*/
    border-radius: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    float: right;
}

.state0 {
    composes: state;
    /*border-color: #2f54eb;*/
    background-color: rgba(255, 255, 255, 0.8);
}

/*.state0 {*/
    /*composes: state;*/
    /*border-color: #2f54eb;*/
/*}*/

.state1 {
    composes: state;
    /*border-color: #fa8c16;*/
    background-color: rgba(250, 140, 22, 0.7);
}

.state2 {
    composes: state;
    /*border-color: #52c41a;*/
    background-color: rgba(82, 196, 26, 0.64);
}

