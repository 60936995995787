.headerWrapper{

    background-color: #ffffff;
    height: auto;
    padding:0;
}
.headerWrapper :global(.ant-page-header){
    /*max-width: 1200px;*/
    margin-left:auto;
    margin-right:auto;
}

.contentWrapper{
    min-height: calc(100vh - 64px);
}
.content{
    padding: 12px;
    position: relative;
    min-height: 100%;
    /*max-width: 1200px;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}

:global(.ant-breadcrumb-link .anticon + span) {
    margin-left: 4px;
}