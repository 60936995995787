.wrapper{
    background-color: #ffffff;
    box-shadow: 1px 1px 3px 0 rgba(115, 143, 147, .2);
    /*color: #39424e;*/
    display: block;
}
.body{
    padding: 12px;
}
.head{
    /*min-height: 48px;*/
    margin-bottom: -1px;
    padding: 0 18px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    background: 0 0;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}
.head .headwrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.head .headwrapper .title{
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 8px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.head .headwrapper .extra{
    float: right;
    margin-left: auto;
    padding: 8px 0;
    color: rgba(0,0,0,.85);
    font-weight: 400;
    font-size: 14px;
}