.firstSider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 80px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;
    background: #001533;
    justify-content: space-between;
}
.menuItem{
    padding: 10px 16px !important;
    text-align: center;
    margin: 0 !important;
    flex-direction: column;
    height: 56px !important;
}
.menuItem > img{
    margin-bottom: 4px;
}
.menuItem:hover{
    background-color: #0000004a;
}
.menuItem :global(.anticon){
    /*margin-right: 5px !important;*/
}
.menuItem span{
    display: block;
    line-height: normal;
    margin-left: 0!important;
}

.logo{
    text-align: center;
    padding:10px;
    font-size: 36px;
    color: #ffffff;
    cursor: pointer;
}
.logo img{
    width: 48px;
    height: 48px;
    border-radius: 24px;
}
.logo2{
    width: 48px;
    /*margin: 10px auto;*/
}
.xiaoxi > span{
    display: block;
}