.item{
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    padding: 8px 8px;
    overflow-wrap: break-word;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.item:hover{
    background-color: #f5f5f5;
}
.item.active{
    background-color: #e6f7ff;
}
.item .desc{
    /*width: 50px;*/
    flex: 1;
}
.item .opt{
    /*width: 50px;*/

}
.item .opt > span{
    margin-left: 5px;
}