.container{
    display: flex;
    align-items: flex-end;
}
.weather{
    display: flex;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    align-items: center;
}
.date{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    align-items: center;
    margin-left: 10px;
}
.time{
    display: inline-block;
    color: #FFFFFF;
    font-size: 32px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    margin-left: 10px;

    height: 50px;
}