.panel{

}
.panelTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.panelTitle{
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;

    font-feature-settings: "kern" on;
    /* Text/colorTextHeading */
    color: rgba(0, 0, 0, 0.88);

    display: flex;
    align-items: center;
    padding: 9px 0;
}

.panelTitle:before{
    content: "";
    display: inline-block;
    width: 3px;
    height: 22px;
    background-color: #1696FF;
    margin-right: 13px;
    border-radius: 3px;
}
.subTitle{
    margin-left: 20px;

    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    font-feature-settings: "kern" on;
    /* Text/colorTextQuaternary */
    color: rgba(0, 0, 0, 0.25);
}