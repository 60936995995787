.table{
    width: 100%;
}
.table th{
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;
    background: #182237;
    height: 72px;
    padding-left: 20px;
}
.table tbody tr:nth-child(odd){

}
.table tbody tr:nth-child(even){
    background: #111826;
}
.table tbody td{
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;

    height: 72px;
    color: rgba(255, 255, 255, 0.65);
    padding-left: 20px;
}